import React from 'react';
import { 
    Container, 
    Box, 
    Typography,
    alpha,
    Grid,
    IconButton
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import InstagramIcon from '@mui/icons-material/Instagram';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupsIcon from '@mui/icons-material/Groups';
import PaidIcon from '@mui/icons-material/Paid';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TikTokIcon from '@mui/icons-material/MusicVideo';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const StatCard = ({ title, value, subtitle, icon, delay }) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay }}
    >
        <Box
            sx={{
                p: 2,
                borderRadius: '16px',
                background: 'rgba(255, 255, 255, 0.03)',
                backdropFilter: 'blur(20px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                display: 'flex',
                alignItems: 'center',
                gap: 2
            }}
        >
            <Box
                sx={{
                    width: 48,
                    height: 48,
                    borderRadius: '12px',
                    background: 'rgba(130, 255, 0, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#82FF00'
                }}
            >
                {icon}
            </Box>
            <Box>
                <Typography variant="h6" sx={{ color: 'white', mb: 0.5 }}>
                    {value}
                </Typography>
                <Typography variant="body2" sx={{ color: alpha('#fff', 0.7) }}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography variant="caption" sx={{ color: '#82FF00', display: 'block' }}>
                        {subtitle}
                    </Typography>
                )}
            </Box>
        </Box>
    </motion.div>
);

const ServiceCard = ({ title, description, gradient, link, delay, gridSize, isSquare }) => (
    <Grid item {...gridSize}>
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => window.location.href = link}
            style={{ 
                height: '100%', 
                cursor: 'pointer',
            }}
        >
            <Box
                sx={{
                    height: '160px',
                    borderRadius: '24px',
                    background: gradient,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    p: 2,
                }}
            >
                <Typography 
                    variant="h4" 
                    sx={{ 
                        color: 'white',
                        fontWeight: 600,
                        mb: 0.5,
                        fontSize: { 
                            xs: isSquare ? '1.5rem' : '1.6rem', 
                            md: isSquare ? '1.6rem' : '1.75rem' 
                        },
                        lineHeight: 1.1
                    }}
                >
                    {title}
                </Typography>
                <Typography 
                    sx={{ 
                        color: alpha('#fff', 0.8),
                        fontSize: { 
                            xs: '0.85rem', 
                            md: '0.9rem' 
                        },
                        lineHeight: 1.3,
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                    }}
                >
                    {description}
                </Typography>
            </Box>
        </motion.div>
    </Grid>
);

const SocialLink = ({ icon, link, delay }) => (
    <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        style={{
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            zIndex: 10
        }}
    >
        <Box
            component="a"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
                width: 50,
                height: 50,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
                color: 'white',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                transition: 'all 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 25px rgba(0,0,0,0.2)',
                }
            }}
        >
            {icon}
        </Box>
    </motion.div>
);

const Navbar = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const scrollToSection = (sectionId) => {
        setMobileMenuOpen(false);
        
        setTimeout(() => {
            const element = document.getElementById(sectionId);
            if (element) {
                const navbarHeight = 80;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.scrollY - navbarHeight;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }, 300);
    };

    const menuItems = [
        { text: 'О компании', section: 'stats' },
        { text: 'Контакты', section: 'contacts' }
    ];

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 100,
                }}
            >
                <Box
                    sx={{
                        background: 'rgba(18, 18, 18, 0.8)',
                        backdropFilter: 'blur(10px)',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                >
                    <Container maxWidth="lg">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                height: '80px',
                                px: { xs: 2, md: 0 }
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                <Box
                                    component="img"
                                    src="https://i.ibb.co.com/brjZp0k/ozen-logo-icon-light.png"
                                    sx={{
                                        width: '40px',
                                        height: '40px',
                                        objectFit: 'contain'
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: { xs: 'none', md: 'flex' },
                                    gap: 4
                                }}
                            >
                                {menuItems.map((item) => (
                                    <Typography
                                        key={item.text}
                                        onClick={() => scrollToSection(item.section)}
                                        sx={{
                                            color: 'white',
                                            cursor: 'pointer',
                                            transition: 'color 0.3s ease',
                                            '&:hover': { color: '#F4F686' }
                                        }}
                                    >
                                        {item.text}
                                    </Typography>
                                ))}
                            </Box>

                            <IconButton 
                                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                                sx={{ 
                                    display: { xs: 'flex', md: 'none' },
                                    color: 'white',
                                    zIndex: 101
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Container>
                </Box>

                <AnimatePresence>
                    {mobileMenuOpen && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                            style={{
                                overflow: 'hidden',
                                background: 'rgba(18, 18, 18, 0.95)',
                                backdropFilter: 'blur(10px)',
                                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                            }}
                        >
                            <Container maxWidth="lg">
                                <Box sx={{ py: 2 }}>
                                    {menuItems.map((item, index) => (
                                        <motion.div
                                            key={item.text}
                                            initial={{ opacity: 0, y: -20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: index * 0.1 }}
                                        >
                                            <Box
                                                onClick={() => scrollToSection(item.section)}
                                                sx={{
                                                    py: 2,
                                                    px: 3,
                                                    cursor: 'pointer',
                                                    transition: 'all 0.3s ease',
                                                    '&:hover': {
                                                        background: 'rgba(255, 255, 255, 0.05)',
                                                    }
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: 'white',
                                                        fontSize: '1.25rem',
                                                        fontWeight: 500,
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {item.text}
                                                </Typography>
                                            </Box>
                                        </motion.div>
                                    ))}
                                </Box>
                            </Container>
                        </motion.div>
                    )}
                </AnimatePresence>
            </Box>
        </>
    );
};

const MainLanding = () => {
    const stats = [
        {
            title: 'Финансовых организаций',
            value: '50+',
            icon: <AccountBalanceIcon />,
            subtitle: 'на платформе'
        },
        {
            title: 'Активных клиентов',
            value: '2,000+',
            icon: <GroupsIcon />,
            subtitle: 'малый и средний бизнес'
        },
        {
            title: 'Выдано займов',
            value: '₸15.5 млрд',
            icon: <PaidIcon />,
            subtitle: 'средний займ ₸2.3 млн'
        }
    ];

    const services = [
        {
            title: 'Finance',
            description: 'Кредитование МСБ',
            gradient: 'linear-gradient(135deg, #F4F686 0%, #A8A45D 100%)',
            link: 'https://ozenfinance.com',
            gridSize: { xs: 6, md: 6 }
        },
        {
            title: 'Factoring',
            description: 'BNPL решения',
            gradient: 'linear-gradient(135deg, #86F4F4 0%, #5DA8A8 100%)',
            link: 'https://factoring.ozenfinance.com',
            gridSize: { xs: 6, md: 6 },
            isSquare: true
        },
        {
            title: 'Guarantor',
            description: 'Банковские гарантии онлайн',
            gradient: 'linear-gradient(135deg, #FF6B6B 0%, #FF4B2B 100%)',
            link: 'https://guarantor.ozen.cc',
            gridSize: { xs: 12, md: 12 }
        },
        {
            title: 'AI Analytics',
            description: 'AI аналитика для МФО',
            gradient: 'linear-gradient(135deg, #56CCF2 0%, #2F80ED 100%)',
            link: 'https://ai.ozenfinance.com',
            gridSize: { xs: 8, md: 8 }
        },
        {
            title: 'Market',
            description: 'Маркетплейс для базаров',
            gradient: 'linear-gradient(135deg, #F4B686 0%, #A87F5D 100%)',
            link: '/market',
            gridSize: { xs: 4, md: 4 }
        }
    ];

    const contacts = [
        {
            icon: <EmailIcon />,
            text: 'info@corp.ozenfinance.com',
            link: 'mailto:info@corp.ozenfinance.com'
        },
        {
            icon: <PhoneIcon />,
            text: '+7 (777) 777-77-77',
            link: 'tel:+77777777777'
        }
    ];

    const socials = [
        {
            icon: <InstagramIcon />,
            link: 'https://instagram.com/ozen_finance',
            gradient: 'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)'
        },
        {
            icon: <TikTokIcon />,
            link: 'https://tiktok.com/@ozen_finance',
            gradient: 'linear-gradient(45deg, #000000, #ffffff)'
        },
        {
            icon: <LinkedInIcon />,
            link: 'https://linkedin.com/company/ozen-finance',
            gradient: 'linear-gradient(45deg, #0077B5, #00A0DC)'
        }
    ];

    return (
        <Box sx={{ pt: '80px' }}>
            <Navbar />
            <Box sx={{ minHeight: '100vh', background: 'linear-gradient(180deg, #000000 0%, #1A1A1A 100%)' }}>
                <Container maxWidth="lg" sx={{ py: { xs: 4, md: 8 } }}>
                    <Box id="products" sx={{ mb: { xs: 8, md: 12 } }}>
                        <Typography 
                            variant="h2" 
                            sx={{ 
                                mb: { xs: 4, md: 6 },
                                color: 'white',
                                fontWeight: 700,
                                fontSize: { xs: '2rem', md: '2.5rem' },
                                textAlign: 'left',
                                pl: 2
                            }}
                        >
                            Наши продукты
                        </Typography>

                        <Grid container spacing={2}>
                            {services.map((service, index) => (
                                <ServiceCard 
                                    key={index} 
                                    {...service} 
                                    delay={0.2 + index * 0.1}
                                />
                            ))}
                        </Grid>
                    </Box>

                    <Box id="stats">
                        <Typography 
                            variant="h2" 
                            sx={{ 
                                mb: { xs: 4, md: 6 },
                                color: 'white',
                                fontWeight: 700,
                                fontSize: { xs: '2rem', md: '2.5rem' },
                                textAlign: 'left',
                                pl: 2
                            }}
                        >
                            О компании
                        </Typography>

                        <Grid container spacing={3}>
                            {stats.map((stat, index) => (
                                <Grid item xs={12} md={4} key={index}>
                                    <StatCard {...stat} delay={0.2 + index * 0.1} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Box id="contacts">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.6 }}
                        >
                            <Box
                                sx={{
                                    mt: { xs: 4, md: 6 },
                                    p: { xs: 3, md: 4 },
                                    borderRadius: '24px',
                                    background: 'rgba(255,255,255,0.03)',
                                    backdropFilter: 'blur(10px)',
                                    border: '1px solid rgba(255, 255, 255, 0.1)',
                                }}
                            >
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        color: 'white',
                                        mb: 3,
                                        textAlign: 'center'
                                    }}
                                >
                                    Связаться с нами
                                </Typography>

                                <Box sx={{ mb: 3 }}>
                                    {contacts.map((contact, index) => (
                                        <Box
                                            key={index}
                                            component="a"
                                            href={contact.link}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                color: 'white',
                                                textDecoration: 'none',
                                                mb: 2,
                                                '&:hover': {
                                                    color: '#82FF00'
                                                }
                                            }}
                                        >
                                            {contact.icon}
                                            <Typography>{contact.text}</Typography>
                                        </Box>
                                    ))}
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: 2
                                    }}
                                >
                                    {socials.map((social, index) => (
                                        <motion.div
                                            key={index}
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                        >
                                            <Box
                                                component="a"
                                                href={social.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    width: { xs: 40, md: 45 },
                                                    height: { xs: 40, md: 45 },
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    background: social.gradient,
                                                    color: 'white',
                                                    transition: 'all 0.3s ease',
                                                    '&:hover': {
                                                        transform: 'translateY(-2px)',
                                                        boxShadow: '0 6px 25px rgba(0,0,0,0.2)',
                                                    }
                                                }}
                                            >
                                                {social.icon}
                                            </Box>
                                        </motion.div>
                                    ))}
                                </Box>
                            </Box>
                        </motion.div>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default MainLanding; 